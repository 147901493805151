<template>
    <video class="bg-hero" playsinline webkit-playsinline muted poster="/images/poster/saintouen-poster.jpg" rel="preload">
        <source :src='this.$pathprod+"videos/entete/video-saintOuen.mp4"' type="video/mp4">
        <source :src='this.$pathprod+"videos/entete/video-saintOuen.webm"' type="video/webm">
    </video>
</template>
<script>
    export default {
        name: 'VideoSaintOuen',
        props: {
            msg: String,
        },
    }
</script>
         